import { NgModule } from '@angular/core';
import { BnNgIdleService } from './bn-ng-idle.service';

@NgModule({
  imports: [
  ],
  declarations: [],
  providers: [BnNgIdleService],
  exports: []
})
export class BnNgIdleModule { }
